import React from 'react';
import posed from 'react-pose';

import { Container, Row, PosedSection } from 'components/layout';
import SectionAside from 'components/typography/SectionAside';
import Content from 'components/typography/Content';
import { PoseableColumn } from 'components/poseable';
import { fadeUp } from 'components/poses';
import validateNode from 'utils/validateNode';
import cssForBreakpoints from 'utils/cssForBreakpoints';

const PosedColumn = posed(PoseableColumn)(fadeUp);

const ExpectationsBlock = ({
  theme,
  asideHeading,
  column1Node,
  column2Node,
  column3Node,
  groupWithNextBlock = false,
  sectionProps = {},
  contentProps = {},
  ...props
}) => {
  const columns = [column1Node, column2Node, column3Node].filter(node =>
    validateNode(node)
  );
  return (
    <PosedSection
      pb={groupWithNextBlock ? theme.gap.groupedWithNextBlock : theme.gap.small}
      {...sectionProps}
    >
      <Container>
        <Row>
          <SectionAside label={asideHeading} theme={theme} />
          {columns.map((node, idx) => (
            <PosedColumn
              key={`column-${idx}`}
              width={[1, 1, 1, 1 / 3, 1 / 4]}
              ml={[
                0,
                0,
                0,
                idx === 0 ? 0 : `${(1 / 12) * 100}%`,
                idx === 0 ? 0 : `${(1 / 12) * 100}%`
              ]}
              mb={[30, 30, 30, 30, 0]}
            >
              <Content
                is={`article`}
                {...contentProps}
                css={`
                  font-size: 20px;
                  & h3 {
                    line-height: 1.0625;
                    font-family: ${theme.fonts.headings};
                    ${cssForBreakpoints(`font-size`, [36, 48, 56, 64, 72, 80])};
                    margin-bottom: 0.375em;
                  }
                `}
                dangerouslySetInnerHTML={{
                  __html: node.childMarkdownRemark.html
                }}
              />
            </PosedColumn>
          ))}
        </Row>
      </Container>
    </PosedSection>
  );
};

export default ExpectationsBlock;
