import { Column, Container, Row } from "components/layout";
import { fade, fadeUp } from "components/poses";
import styled, { css, withTheme } from "styled-components";

import { Flex } from "rebass";
import { PoseableBox } from "components/poseable";
import React from "react";
import VisuallyHidden from "@reach/visually-hidden";
import posed from "react-pose";

const REPORT_FILENAME = `Camp Jefferson - Why We Buy - Splurging.pdf`;

const Label = styled("label")`
  display: block;
  font-family: ${(props) => props.theme.fonts.small};
  font-size: 16px;
  padding: 0 8px;
  margin-bottom: 8px;
`;

const Input = styled("input")`
  display: block;
  width: 100%;
  background: ${(props) => props.theme.colors.black};
  border: 1px solid ${(props) => props.theme.colors.blackLineColor};
  border-radius: 0;
  padding: 8px;
  color: white;
  font-family: ${(props) => props.theme.fonts.small};
  font-size: 16px;
  &[type="checkbox"] {
    width: auto;
    display: inline-block;
    margin-right: 10px;
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.blackLineColor};
    border-width: 1px;
  }
  &.error {
    border-color: ${(props) => props.theme.colors.redLineColor};
    border-width: 1px;
  }
`;

const ErrorSpan = styled("span")`
  font-family: Calibre;
  position: absolute;
  top: 100%;
  right: 13px;
  margin-top: -10px;
  color: ${(props) => props.theme.colors.red};
  display: none;
  &.visible {
    display: block;
  }
`;

const SubmitButton = styled("button")`
  display: block;
  background: transparent;
  border: 1px solid ${(props) => props.color};
  padding: 8px;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.small};
  font-size: 16px;
`;

function downloadFile(filePath) {
  var link = document.createElement("a");
  link.href = filePath;
  link.download = REPORT_FILENAME;
  link.rel = "noopener noreferrer";
  link.target = "_blank";
  link.click();
}

const Overlay = styled(posed(PoseableBox)(fadeUp))`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(246, 223, 116, 1);
`;

const SuccessMessage = styled(posed(PoseableBox)(fadeUp))`
  font-family: Druk;
  text-align: center;
  color: ${(props) => props.theme.colors.black};
`;
const SendingMessage = styled(posed(PoseableBox)(fade))`
  font-family: Druk;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: ${(props) => props.theme.colors.black};
`;
const DownloadLink = styled(posed(`div`)(fadeUp))``;

function SplurgeForm({ theme }) {
  let [downloadUrl, setDownloadUrl] = React.useState(null);
  let [formState, setFormState] = React.useState({ display: "form" });

  const handleSubmit = async (e) => {
    setFormState({ display: "sending" });
    e.preventDefault();
    let formData = new FormData(document.getElementById("splurge-form"));
    let meta = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    };

    let pdfResult = await fetch(
      "/.netlify/functions/get-splurge-pdf",
      meta
    ).then((r) => r.json());

    try {
      if (pdfResult.errors) {
        setFormState({ display: "error", errors: pdfResult.errors });
      } else {
        let formResult = await fetch("/", meta)
          .then((r) => {
            if (r.status === 200) {
              return { success: true };
            }
          })
          .catch((error) =>
            setFormState({ display: "error", errors: [error] })
          );

        if (formResult.success) {
          let url = pdfResult.url; //URL.createObjectURL(pdfResult);
          downloadFile(url);
          setDownloadUrl(url);
          setFormState({ display: "success" });
        }
      }
    } catch (e) {}
  };

  const removeError = (e) => {
    let input = e.target;
    input.classList.remove("error");
    let name = e.target.getAttribute("name");
    let errorSpan = document.getElementById(`${name}-error`);
    if (errorSpan) {
      errorSpan.classList.remove("visible");
    }
  };

  React.useEffect(() => {
    if (formState.display === `error`) {
      let errors = formState.errors;
      errors.forEach((error) => {
        let name = error.name;
        if (name) {
          name = name.toLowerCase();
        }
        let field = document.querySelector(
          `#splurge-form input[name="${name}"]`
        );
        if (!field) {
          return;
        }
        field.classList.add("error");
        let errorSpan = document.getElementById(`${name}-error`);
        if (errorSpan && !errorSpan.classList.contains("visible")) {
          errorSpan.innerHTML = error.message || error.errorMessage;
          errorSpan.classList.add("visible");
        }
      });
    }
  }, [formState]);

  return (
    <Container mt={[40, 60, 80]}>
      <form
        id="splurge-form"
        name="splurge"
        method="POST"
        data-netlify="true"
        style={{ width: "100%", display: "block" }}
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <VisuallyHidden>
          <label>
            Don’t fill this out if you’re human: <input name="bot-field" />
          </label>
        </VisuallyHidden>
        <input type="hidden" name="form-name" value="splurge"></input>
        <Row>
          <Column width={[1, 1, 1, 1 / 3]}>
            <Label htmlFor="first_name">First Name:</Label>
            <Input
              type="text"
              name="first_name"
              required
              onFocus={removeError}
            />
            <ErrorSpan id="first_name-error"></ErrorSpan>
          </Column>
          <Column width={[1, 1, 1, 1 / 3]}>
            <Label htmlFor="last_name">Last Name:</Label>
            <Input
              type="text"
              name="last_name"
              required
              onFocus={removeError}
            />
            <ErrorSpan id="last_name-error"></ErrorSpan>
          </Column>
          <Column width={[1, 1, 1, 1 / 3]}>
            <Label htmlFor="company_name">Company Name:</Label>
            <Input
              type="text"
              name="company_name"
              required
              onFocus={removeError}
            />
            <ErrorSpan id="company_name-error"></ErrorSpan>
          </Column>
          <Column width={[1, 1, 1, 1 / 2]}>
            <Label htmlFor="job_title">Job title:</Label>
            <Input
              type="text"
              name="job_title"
              required
              onFocus={removeError}
            />
            <ErrorSpan id="job_title-error"></ErrorSpan>
          </Column>
          <Column width={[1, 1, 1, 1 / 2]}>
            <Label htmlFor="email">Your Email:</Label>
            <Input type="email" name="email" required onFocus={removeError} />
            <ErrorSpan id="email-error"></ErrorSpan>
          </Column>
          <Column width={[1, 1, 1, 1 / 2]}>
            <Label>
              <Input type="checkbox" name="optin" defaultChecked={true} />
              I'd like to hear more from Camp Jefferson in the future.{" "}
            </Label>
          </Column>
          <Column width={[1, 1, 1, 1 / 2]}>
            <Flex justifyContent={["start", "start", "end"]}>
              <a
                href="https://www.campjefferson.com/files/1594994494-v7-privacy-policy-en-2020.pdf"
                title="V7 Privacy Policy"
                target="_blank"
                download
                css={{
                  fontFamily: "Calibre",
                  textDecoration: "none",
                  color: "inherit",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                V7 Privacy Policy
              </a>
            </Flex>
          </Column>
          <Column width={1}>
            <SubmitButton type="submit" color={theme.colors.black}>
              Download the Report
            </SubmitButton>
          </Column>
        </Row>
      </form>
      {(formState.display === `sending` || formState.display === `success`) && (
        <Overlay offset={0} initialPose="hidden" pose={"visible"}>
          <SendingMessage
            fontSize={[60, 80, 90, 100]}
            offset={0}
            initialPose="hidden"
            pose={formState.display === `sending` ? `visible` : `hidden`}
          >
            SENDING...
          </SendingMessage>
          {formState.display === `success` && (
            <>
              <SuccessMessage
                fontSize={[80, 100, 120, 150]}
                offset={3}
                initialPose="hidden"
                pose={"visible"}
                visibledelay={300}
              >
                THANK YOU
              </SuccessMessage>
              <DownloadLink
                initialPose="hidden"
                visibledelay={600}
                offset={2}
                pose={"visible"}
                css={{
                  fontsize: 24,
                  marginTop: 10,
                  color: theme.colors.black,
                  "& a": { color: "inherit", textDecoration: "underline" },
                }}
              >
                The report is downloading now. If you have any trouble, click
                {` `}
                <a
                  href={downloadUrl}
                  download={REPORT_FILENAME}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </DownloadLink>
            </>
          )}
        </Overlay>
      )}
    </Container>
  );
}

export default withTheme(SplurgeForm);
