import * as easings from "d3-ease";

import { Container, PosedSection, Row } from "components/layout";
import { PoseableBox, PoseableColumn } from "components/poseable";
import React, { useState } from "react";

import Content from "components/typography/Content";
import EventForm from "./EventForm";
import SectionAside from "components/typography/SectionAside";
import { Text } from "rebass";
import { fadeUp } from "components/poses";
import posed from "react-pose";
import { smallHeading } from "components/typography/sizes";

const PosedColumn = posed(PoseableColumn)(fadeUp);

const PosedMessageContainer = posed(PoseableBox)({
  inactive: { opacity: 0, visibility: `hidden` },
  active: {
    opacity: 1,
    applyAtStart: { visibility: `visible` },
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinOut,
    },
  },
});

const boxProps = {
  inactive: {
    opacity: 0,
    x: `-50%`,
    y: `calc(-50% + 5px)`,
    visibility: `hidden`,
  },
  active: {
    opacity: 1,
    x: `-50%`,
    y: `-50%`,
    applyAtStart: { visibility: `visible` },
    transition: ({ visibledelay = 0 }) => ({
      type: "tween",
      duration: 300,
      delay: visibledelay,
      ease: easings.easeSinOut,
    }),
  },
  done: {
    opacity: 0,
    x: `-50%`,
    y: `calc(-50% - 10px)`,
    applyAtEnd: { visibility: `hidden` },
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinIn,
    },
  },
};

const PosedSending = posed(PoseableBox)(boxProps);
const PosedThanks = posed(PoseableBox)(boxProps);

function FormBlock({
  active,
  theme,
  asideHeading,
  formIntroNode,
  formName = null,
  jobOptions,
  sectionProps = {},
  contentProps = {},
}) {
  let opts = [];
  if (jobOptions) {
    opts = jobOptions.split(`,`).map((str) => str.trim());
  }
  const [sending, setSending] = useState(false);
  const [thanks, setThanks] = useState(false);

  return (
    <PosedSection pb={theme.gap.small} {...sectionProps}>
      <Container>
        <Row>
          <SectionAside label={asideHeading} theme={theme} />
          <PosedColumn width={[1, 1, 1, 11 / 12]}>
            {active === true ? (
              <React.Fragment>
                <EventForm
                  name={formName}
                  introNode={formIntroNode}
                  introContentProps={contentProps}
                  // jobOptions={opts}
                  sending={sending}
                  setSending={setSending}
                  setThanks={setThanks}
                />
                <PosedMessageContainer
                  pose={thanks || sending ? `active` : `inactive`}
                  style={{
                    position: `absolute`,
                    top: 0,
                    left: 0,
                    width: `100%`,
                    height: `100%`,
                  }}
                >
                  <PosedSending
                    withParent={false}
                    initialPose={`inactive`}
                    pose={sending ? `active` : thanks ? `done` : `inactive`}
                    width={[`90%`, `75%`]}
                    p={[20, 30, 40]}
                    bg={theme.colors.red}
                    style={{
                      position: `absolute`,
                      top: `50%`,
                      left: `50%`,
                    }}
                  >
                    <Text
                      color={theme.colors.black}
                      fontSize={[24, 32, 36]}
                      textAlign={`center`}
                      mb={0}
                    >
                      Sending...
                    </Text>
                  </PosedSending>
                  <PosedThanks
                    withParent={false}
                    initialPose={`inactive`}
                    pose={thanks ? `active` : `inactive`}
                    width={[`90%`, `75%`]}
                    p={[20, 30, 40]}
                    bg={theme.colors.red}
                    visibledelay={1000}
                    style={{
                      position: `absolute`,
                      top: `50%`,
                      left: `50%`,
                    }}
                  >
                    <Text
                      color={theme.colors.black}
                      fontSize={[24, 32, 36, 42]}
                      textAlign={`center`}
                      mb={[10, 20, 25]}
                    >
                      Thanks!
                    </Text>
                    <Text
                      color={theme.colors.black}
                      fontFamily={theme.fonts.small}
                      fontSize={[18, 20]}
                      textAlign={`center`}
                    >
                      Your application was submitted.
                    </Text>
                  </PosedThanks>
                </PosedMessageContainer>
              </React.Fragment>
            ) : (
              <Content
                is={`article`}
                {...contentProps}
                dangerouslySetInnerHTML={{
                  __html: formIntroNode.childMarkdownRemark.html,
                }}
                mr={[`25%`]}
                mb={[30, 54]}
                fontSize={smallHeading}
              />
            )}
          </PosedColumn>
        </Row>
      </Container>
    </PosedSection>
  );
}

export default FormBlock;
