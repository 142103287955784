import * as easings from "d3-ease";

import { Column, Row } from "components/layout";
import { PoseableBox, PoseableText } from "components/poseable";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { normal, smallHeading } from "components/typography/sizes";
import styled, { withTheme } from "styled-components";

import ButtonArrow from "components/icons/ButtonArrow";
import Content from "components/typography/Content";
import { Text } from "rebass";
import TextButtonWithArrow from "components/buttons/TextButtonWithArrow";
import VisuallyHidden from "@reach/visually-hidden";
import posed from "react-pose";

const PosedForm = posed(PoseableBox)({
  normal: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinIn,
    },
  },
  sending: {
    opacity: 0.5,
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinOut,
    },
  },
  submitted: {
    opacity: 0.25,
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinOut,
    },
  },
});

const Input = styled(Text)`
  color: white;
  border-radius: 0;
  height: 50px;
  width: 100%;
  background: rgba(216, 216, 216, 0.5);
  border: 1px solid #979797;
  line-height: 1em;
  padding: 12px 20px;
  font-family: ${({ theme }) => theme.fonts.small};
  will-change: border-color;
  transition: border-color 0.2s ease-in;
  ::placeholder {
    color: white;
    opacity: 0.5;
  }

  :hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
    transition: border-color 0.2s ease-out;
  }
  :focus {
    outline: none;
    border: 1px solid #fff;
    transition: border-color 0.2s ease-out;
  }

  &.error {
    border-color: #f48572;
    transition: border-color 0.2s ease-out;
    color: #f48572;
    ::placeholder {
      color: #f48572;
      opacity: 1;
    }
  }
`;

const StyledFileUpload = styled(
  posed(PoseableText)({ hoverable: true, focusable: true })
)`
  position: relative;
  display: inline-block;
  margin-top: 5px;
  align-items: center;

  & input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    cursor: pointer;
  }

  & input + label {
    font-size: inherit;
    display: inline-block;
    cursor: pointer;
  }

  & svg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    :nth-child(2) {
      top: 0.5em;
      right: 0.5em;
    }
  }

  & span {
    position: absolute;
    opacity: 0.5;
    right: -3em;
    top: 50%;
    transform: translate3d(100%, calc(-50% + 0.1em), 0);
  }
`;

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validateElement(element, errors) {
  switch (element.getAttribute(`name`)) {
    case `name`:
    case `dream_job`:
    case `advertising`:
      if (element.value.trim() === ``) {
        errors.push(element);
      }
      break;
    case `email`:
      if (!validateEmail(element.value)) {
        errors.push(element);
      }
      break;
    case `job_name`:
      if (element.value === `I'm applying for…`) {
        errors.push(element);
      }
      break;
    default:
      break;
  }
}

function getFormValues(elements) {
  let result = {};
  elements.forEach((element) => {
    if (element.getAttribute(`type`) === `file`) {
      result[element.getAttribute(`name`)] = element.files[0];
    } else {
      result[element.getAttribute(`name`)] = element.value;
    }
  });
  return result;
}

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

function EventForm({
  theme,
  name,
  introNode,
  introContentProps,
  sending,
  setSending,
  setThanks,
  pose,
}) {
  const formRef = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fileValue, setFileValue] = useState(null);
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState([]);

  // let opts = useMemo(() => {
  //   return [`I'm applying for…`].concat(jobOptions);
  // }, [jobOptions]);

  const validate = (e) => {
    e.preventDefault();
    e.target.classList.remove("error");
    const elements = Array.from(formRef.current.elements).filter(
      (el) => el.type !== "submit"
    );

    let validationErrors = [];

    elements.forEach((element) => {
      validateElement(element, validationErrors);
    });

    if (validationErrors.length === 0) {
      setValid(true);
    } else {
      setErrors(validationErrors);
    }
    return false;
  };

  const resetError = (e) => {
    e.target.classList.remove("error");
  };

  const uploadChange = (e) => {
    let fileName = "";
    const input = e.target;
    if (input.files && input.files.length > 1) {
      fileName = `(${input.files.length} files selected)`;
    } else {
      fileName = `(${input.value.split("\\").pop()})`;
    }
    setFileValue(fileName);
  };

  useEffect(() => {
    errors.forEach((el) => {
      if (!el.classList.contains("error")) {
        el.classList.add(`error`);
      }
    });
  }, [errors]);

  useEffect(() => {
    if (!valid) {
      setIsSubmitted(false);
      return;
    }
    const elements = Array.from(formRef.current.elements).filter(
      (el) => el.type !== "submit"
    );
    const values = getFormValues(elements);
    if (isSubmitted && valid && !sending) {
      setSending(true);
      fetch(`/`, {
        method: "POST",
        body: encode({
          "form-name": formRef.current.getAttribute("name"),
          ...values,
        }),
      })
        .then((r) => {
          if (r && r.ok === true) {
            setTimeout(() => {
              setThanks(true);
            }, 2000);
          }
        })
        .catch((error) => {
          alert(error);
          setIsSubmitted(false);
        });
    }
  }, [isSubmitted, setSending, setThanks, valid]);

  return (
    <PosedForm
      ref={formRef}
      name={name || `event form application`}
      as={`form`}
      width={1}
      onSubmit={(e) => {
        e.preventDefault();
        setIsSubmitted(true);
        return false;
      }}
      method="POST"
      netlify
      data-netlify="true"
      netlify-honeypot="bot-field"
      data-netlify-honeypot="bot-field"
      action={`/`}
      pose={sending ? `sending` : isSubmitted ? `submitted` : `normal`}
    >
      <Content
        is={`article`}
        {...introContentProps}
        dangerouslySetInnerHTML={{
          __html: introNode.childMarkdownRemark.html,
        }}
        mr={[`25%`]}
        mb={[30, 54]}
        fontSize={smallHeading}
      />
      <input
        type="hidden"
        name="form-name"
        value={name || `event form application`}
      />
      <VisuallyHidden>
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
      </VisuallyHidden>
      <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
        <Column width={[1, 1, `50%`]}>
          <Input
            name={`name`}
            as={`input`}
            type={`text`}
            placeholder={`First & Last Name`}
            fontSize={normal}
            onFocus={resetError}
            req
            onBlur={validate}
          />
        </Column>
        <Column width={[1, 1, `50%`]}>
          <Input
            name={`email`}
            as={`input`}
            type={`text`}
            placeholder={`Contact email`}
            fontSize={normal}
            onFocus={resetError}
            req
            onBlur={validate}
          />
        </Column>
      </Row>
      <Row pb={[20, 20, 35]}>
        {/* <Column width={[1, 1, `50%`]}>
          <Input
            as={`select`}
            name={`job_name`}
            fontSize={normal}
            onFocus={resetError}
            style={{ textIndent: 12 }}
            req
            onBlur={validate}
          >
            {opts.map((optionName) => (
              <option key={`intakeform-${optionName}`} value={optionName}>
                {optionName}
              </option>
            ))}
          </Input>
        </Column> */}
        <Column width={[1]}>
          <Input
            name={`portfolio_link`}
            as={`input`}
            type={`text`}
            placeholder={`Porfolio Link (optional)`}
            fontSize={normal}
            onFocus={resetError}
          />
        </Column>
      </Row>
      <Row pt={[20, 20]} ml={0}>
        <Column width={[1, 1, `50%`]}>
          <StyledFileUpload
            color={theme.colors.white}
            fontFamily={theme.fonts.small}
            fontSize={[24, 30]}
            fontWeight={`bold`}
          >
            <input
              id={"file"}
              type="file"
              name="file"
              onChange={uploadChange}
            />
            <label htmlFor="file">Upload your portfolio</label>
            <figure
              style={{
                position: `absolute`,
                overflow: `hidden`,
                top: 0,
                right: `-1em`,
                width: `0.5em`,
                height: `0.5em`,
              }}
            >
              <ButtonArrow
                fill={theme.colors.white}
                width={`0.5em`}
                height={`0.5em`}
              />
            </figure>
            {fileValue && (
              <Text as={`span`} fontFamily={theme.fonts.body} fontSize={normal}>
                {fileValue}
              </Text>
            )}
          </StyledFileUpload>
        </Column>
      </Row>
      <TextButtonWithArrow
        color={theme.colors.white}
        fontSize={[24, 30]}
        px={0}
        mt={[20, 30]}
        as={`button`}
        label={`Submit`}
        style={{
          cursor: valid ? `pointer` : `default`,
          opacity: valid ? 1 : 0.5,
          willChange: `opacity`,
          transition: `opacity 0.2s ease-out`,
        }}
      />
    </PosedForm>
  );
}

export default withTheme(EventForm);
